import React from 'react';
import { StyleSheet, Text, View, Image, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useFonts } from 'expo-font';

const ComingSoon = () => {
  const [fontsLoaded] = useFonts({
    'FamiljenGrotesk': require('../fonts/Family-nR2ag.otf'),
    'FamiljenGrotesk-Bold': require('../fonts/Family-nR2ag.otf'),
  });

  if (!fontsLoaded) {
    return <View style={styles.container} />;
  }

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={['rgba(30,214,192,1)', 'rgba(121,42,232,1)']}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        style={styles.gradient}
      >
        <View style={styles.header}>
          <Image 
            source={require('../assets/flomodo logo wide.png')} 
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>Coming Soon!</Text>
          <Text style={[styles.subtitle, styles.gradientText]}>
            We're working hard to{'\n'} bring you something amazing
          </Text>
        </View>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(30,214,192,1)',
  },
  gradient: {
    flex: 1,
    width: '100%',
    height: '100vh',
  },
  header: {
    height: 100,
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0)',
    justifyContent: 'center',
    paddingHorizontal: 40,
    position: 'absolute',
    top: 0,
    left: 0,
    
  },
  logo: {
    width: 250,
    height: 100,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 104,
    color: '#ffffff',
    marginBottom: 20,
    fontWeight: '600',
    fontFamily: 'FamiljenGrotesk-Bold',
  },
  subtitle: {
    fontSize: 42,
    
    opacity: 0.9,
    textAlign: 'center',
    fontWeight: '400',
    // fontFamily: 'FamiljenGrotesk',
    fontFamily: 'Inter'
  },
  gradientText: {
    backgroundImage: 'linear-gradient(to right, #FFEAA6, #49FFED)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    width: 'fit-content',
    maxWidth: '80%',
  },
});

export default ComingSoon; 